import styled from 'styled-components'
import { Form } from 'formik'

export const StyledForm = styled(Form)`
  position: relative !impotant;
  display: flex !important;
  flex-direction: column !important;
  background-color: transparent;
  width: 90% !important;
  margin: 0 auto;
`

export const StyledButton = styled.button`
  display: inline-block;
  width: 60%;
  padding: .3rem 1.5rem;
  border-radius: 30rem;
  border: none !important;
  margin:0 auto 1rem;
  font-weight: 700;
  cursor: pointer;
`